<template>
  <v-app>
    <nav-component :drawer="false" title="Login"/>
    <v-main>
      <div style="alignment: center;">
        <v-card style="max-width: 600px; margin: 150px auto auto auto; padding: 50px;">
          <h1>Login</h1>
          <div style="display: flex; flex-direction: row; justify-content: center;">
            <span style="width: 80px; margin-top: 21px; margin-right: 10px;">Token:</span>
            <v-text-field type="number" v-model="token" :rules="[validationRules.token]"/>
          </div>
          <div :class="{'shake': shakeErrorMessage}"
               style="display: flex; flex-direction: row; justify-content: left; color: red;" v-if="errorMessage">
            <span v-text="errorMessage"/>
          </div>
          <p>If your token is not working, contact your admin to reset 2FA for your user.</p>
          <v-btn color="secondary" @click="login">login</v-btn>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import utils from "@/mixins/utils";
import NavComponent from "@/components/NavComponent";
import authService from '@/authService';

export default {
  mixins: [utils],
  data() {
    return {
      token: null,
      errorMessage: null,
      shakeErrorMessage: false,
      validationRules: {
        // eslint-disable-next-line no-useless-escape
        token: v => (v && /^\d{6}$/.test(v)) || 'Token should be 6 digits',
      },
    }
  },
  components: {
    NavComponent
  },
  methods: {
    async login() {
      if (this.validationRules.token(this.token) !== true) {
        return
      }
      await this.httpRequest('POST', 'api/auth/signIn', {'token': this.token})
          .then(signInResult => {
                authService.setSignedInUser(signInResult.data);
                this.$router.replace({path: '/'});
              },
              error => this.handleError(error));
    },
    handleError(error) {
      const prevErrorMessage = this.errorMessage;
      if (error.response && error.response.headers['x-error-message']) {
        this.errorMessage = error.response.headers['x-error-message'];
      } else {
        this.errorMessage = 'Incorrect token';
      }
      if (prevErrorMessage === this.errorMessage)
        this.triggerShake();
    },
    triggerShake() {
      this.$nextTick(() => {
        this.shakeErrorMessage = true;
        setTimeout(() => {
          this.shakeErrorMessage = false;
        }, 1000); // Duration of the shake animation
      });
    },
  }
}
</script>
