import HomeComponent from './pages/index.vue'
import LoginComponent from './pages/login.vue'
import Login2Component from './pages/login2.vue'
import RegisterComponent from './pages/register.vue'
import OverviewComponent from './pages/overview/index.vue'
import CustomerComponent from './pages/customer/index.vue'
import MNOComponent from './pages/mno/index.vue'
import AdminUsersComponent from './pages/admin/users/index.vue'
import AdminCustomersComponent from "./pages/admin/customers/index.vue";

export const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeComponent,
    children: [
      {
        path: '',
        name: 'overview',
        component: OverviewComponent,
        meta: { requiresAuth: true }
      },
      {
        path: 'customer',
        name: 'customer',
        component: CustomerComponent,
        meta: { requiresAuth: true }
      },
      {
        path: 'mno',
        name: 'mno',
        component: MNOComponent,
        meta: { requiresAuth: true }
      },
      {
        path: 'admin/customers',
        name: 'admin-customer',
        component: AdminCustomersComponent,
        meta: { requiresAuth: true }
      },
      {
        path: 'admin/users',
        name: 'admin-users',
        component: AdminUsersComponent,
        meta: { requiresAuth: true }
      },
      {
        path: 'signIn',
        name: 'signIn',
        component: Login2Component,
        meta: { mfa: true }
      },
      {
        path: 'register',
        name: 'register',
        component: RegisterComponent,
        meta: { mfa: true }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginComponent
  }
]
