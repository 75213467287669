<template>
  <v-card flat>
    <v-card-title>MSISDN Report</v-card-title>
    <v-card-subtitle v-if="results && results.length">
      Activity log of a given MSISDN
    </v-card-subtitle>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
                clearable
                outlined
                dense
                hide-details="auto"
                v-model="msisdn"
                label="MSISDN"
                type="text"
                clear-icon="mdi-close-circle"
                append-icon="mdi-send"
                @click:append="onMsisdnChange"
            />
          </v-col>
        </v-row>

        <v-row v-if="totals">
          <v-col cols="12" sm="3">
            <v-card tile>
              <v-card-subtitle class="font-weight-medium text-center">First SIM registration</v-card-subtitle>
              <v-card-text class="text-center">
                <span v-if="totals.registration" v-text="$date(new Date(totals.registration), 'Ppp')"/>
                <span v-else>N/A</span>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="3">
            <v-card tile>
              <v-card-subtitle class="font-weight-medium text-center">First API transaction</v-card-subtitle>
              <v-card-text class="text-center">
                <span v-if="totals.firstTransaction" v-text="$date(new Date(totals.firstTransaction), 'Ppp')"/>
                <span v-else>N/A</span>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="3">
            <v-card tile>
              <v-card-subtitle class="font-weight-medium text-center">Total validations</v-card-subtitle>
              <v-card-text class="text-center">
                {{ totals.transactions.validation.completed }} completed requests.
                {{ totals.transactions.validation.status["VALID"] || 0 }} valid.
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="3">
            <v-card tile>
              <v-card-subtitle class="font-weight-medium text-center">Total approvals</v-card-subtitle>
              <v-card-text class="text-center">
                {{ totals.transactions.approval.completed }} completed requests.
                <br/>
                {{ totals.transactions.approval.status["APPROVE"] || 0 }} Approve,
                {{ totals.transactions.approval.status["NOT_APPROVED"] || 0 }} Not Approved,
                {{ totals.transactions.approval.status["IGNORE"] || 0 }} Ignore.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-data-table hide-default-footer disable-pagination
                          :loading="loading"
                          loading-text="Loading... Please wait"
                          :headers="[
                      { text: 'Date', value: 'timestamp' },
                      { text: 'Action', value: 'action' },
                      { text: 'Request ID', value: 'requestId' },
                    ]"
                          :items="results">
              <template v-slot:item.timestamp="{ item }">
                <span v-text="$date(new Date(item.timestamp), 'Ppp')"/>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import utils from "@/mixins/utils";

export default {
  name: "MSISDNReport",
  mixins: [utils],
  props: {},
  data() {
    return {
      loading: false,
      msisdn: null,
      dateRange: null,
      results: [],
      totals: null
    }
  },
  methods: {
    onMsisdnChange() {
      this.loadTotals();
      this.loadData();
    },
    setDateRange(dateRange) {
      this.dateRange = dateRange;
      this.loadData();
    },
    async loadTotals() {
      if (!this.msisdn) return;
      this.totals = (await this.httpRequest('GET','api/report/mno/msisdn/totals?' + new URLSearchParams({msisdn: this.msisdn}))).data;
    },
    async loadData() {
      if (!this.msisdn) return;

      const params = {msisdn: this.msisdn, ...this.dateRange};
      this.loading = true;
      this.results = (await this.httpRequest('GET','api/report/mno/msisdn/details?' + new URLSearchParams(params))).data;
      this.loading = false;
    }
  }
}
</script>
