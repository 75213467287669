<template>
  <v-card flat>
    <v-row>
      <v-card-title>Users Report</v-card-title>
      <v-card-actions>
        <v-btn v-if="results && results.length" block color="secondary" v-text="'EXPORT'" @click="userReportCsv"/>
      </v-card-actions>
    </v-row>
    <v-card-subtitle v-if="results && results.length">
      Total in period: {{ totals.users }} Applet Registrations and {{ totals.uniqueActiveUsers }} Unique MSISDNs in API
    </v-card-subtitle>

    <v-card-text>
      <v-data-table hide-default-footer disable-pagination
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :headers="[
                      { text: 'Date', value: 'date' },
                      { text: 'Applet Registrations', value: 'users' },
                      { text: 'Unique MSISDNs in API', value: 'uniqueActiveUsers' },
                    ]"
                    :items="results">
        <template v-slot:item.date="{ item }">
          <span v-text="$date(item.date, 'P')"/>
        </template>
      </v-data-table>
    </v-card-text>

  </v-card>
</template>

<script>
import utils from "@/mixins/utils";

export default {
  name: "UsersReport",
  mixins: [utils],
  props: {},
  data() {
    return {
      loading: false,
      results: [],
      totals: {}
    }
  },
  methods: {
    async loadData(params) {
      this.loading = true;

      this.results = (await this.httpRequest('GET','api/report/mno/users?' + new URLSearchParams(params))).data
          .map(item => ({
            date: new Date(item[0]),
            ...item[1]
          }));

      this.totals = {
        users: this.results.reduce((sum, x) => (sum + x.users), 0),
        uniqueActiveUsers: this.results.reduce((sum, x) => (sum + x.uniqueActiveUsers), 0),
      }

      this.loading = false;
    },
    userReportCsv() {
      this.dateFormat = this.time === 'HOURLY' ? 'yyyy-MM-dd HH:00' : 'yyyy-MM-dd'
      let blobHeaders = 'Date, Validate, Approve\r\n'
      this.csvGeneric('UserRepository', blobHeaders,(item) => {
        return this.$date(new Date(item.date), this.dateFormat) + ',' +
            item.users + ',' +
            item.uniqueActiveUsers + '\r\n';
      });
    },
  }
}
</script>
