<template>
  <v-app>
    <nav-component :drawer="false" title="Register"/>
    <v-main>
      <div style="alignment: center;">
        <v-card style="max-width: 600px; margin: 150px auto auto auto; padding: 50px;">
          <h1>Register</h1>
          <v-img v-if="imageSrc" :src="imageSrc" />
          <p>Scan this QR code with the Google Authenticator app.</p>
          <v-btn color="secondary" @click="next">Next</v-btn>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import utils from "@/mixins/utils";
import NavComponent from "@/components/NavComponent";

export default {
  async mounted() {
    const result = await this.httpRequest('GET','api/auth/register');
    if (result) {
        if (result.data) {
          this.imageSrc = result.data;
        } else {
          this.$router.replace({path: "/login2"});
        }
    }
  },
  mixins: [utils],
  components: {
    NavComponent
  },
  methods: {
    next() {
      this.$router.replace({path: "/login2"});
    }
  },
  data() {
    return {
      imageSrc: null,
    };
  }
}
</script>
