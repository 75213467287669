<template>
  <v-app>
    <nav-component :drawer="true" title="Dashboard Users">
    </nav-component>
    <v-main class="pt-2 pt-md-0">
      <user-component @reload="loadData()" :user="this.activeItem" ref="userComponent"/>

      <alert-component ref="disableAlert"/>
      <v-data-table :headers="headers()"
                    :items="users">
        <template v-slot:top>
          <v-toolbar flat color=" blue-grey lighten-5">
            <v-toolbar-title>Users list</v-toolbar-title>
            <v-switch label="Display disabled" v-model="shouldDisplayDisabled"/>
            <v-spacer/>
            <v-btn :disabled="!hasRole(['ROLE_ADMIN'])"
                   color="secondary" @click="openDialog">
              <v-icon>mdi-plus</v-icon>
              New Item
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <timeago :datetime="item.createdAt"/>
        </template>
        <template v-slot:item.role="{ item }">
          {{ roles[item.role] }}
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <timeago :datetime="item.updatedAt"/>
        </template>
        <template v-slot:item.delete="{item}">

          <v-icon v-if="hasRole(['ROLE_ADMIN'])"
                  small class="mr-2" @click="editItem(item)">mdi-pencil
          </v-icon>
          <v-icon v-if="hasRole(['ROLE_ADMIN'])" @click="disableItem(item)" small class="mr-2"
                  :class="{'color--red': item.delete}">
            mdi-block-helper
          </v-icon>
          <span v-if="!hasRole(['ROLE_ADMIN']) && item.delete" type="text">Disabled</span>
        </template>
        <template v-slot:body.append>
          <tr>
            <td>
              <v-text-field v-model="usernameContains" type="text" label="unsername"/>
            </td>
            <v-select v-model="roleContains" type="text" label="Role"
                      :items="Object.keys(roles).map(r => {return {text: roles[r], value: r}})"/>
            <td/>
            <td/>
            <td>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-main>
  </v-app>
</template>

<script>

import utils from '@/mixins/utils';
import UserComponent from '@/components/UserComponent.vue';
import NavComponent from '@/components/NavComponent.vue';
import AlertComponent from "@/components/AlertComponent.vue";

export default {
  components: {AlertComponent, UserComponent, NavComponent},
  async mounted() {
    await this.loadData();
  },
  mixins: [utils],
  methods: {
    async loadData() {
      const results = await this.httpRequest('GET','api/user');
      this.users = results.data;
    },
    async openDialog() {
      this.activeItem = null
      this.$refs.userComponent.openDialog();
    },
    editItem(item) {
      this.activeItem = Object.assign({}, item)
      this.$refs.userComponent.openDialog();
    },
    async disableItem(item) {
      this.$refs.disableAlert.show('Disable User', item.delete ? 'Are you sure you want to enable ' + item.username + '?' : 'Are you sure you want to disable ' + item.username + '?',
          [{
            text: 'Yes', callback: () => {
              this.disableItemConfirm(item)
            }
          }, {text: 'No'}])
    },
    async disableItemConfirm(item) {
      item.delete = !item.delete
      await this.httpRequest('POST','api/user/disable', null, item)

      await this.closeDisableDialog(true)
    },
    async closeDisableDialog(shouldRefresh) {
      this.disableAlert = false
      if (shouldRefresh)
        await this.loadData()
    },
    headers() {
      return [
        {
          text: 'Username', value: 'username', filter: value => {
            if (!this.usernameContains) return true
            return value && value.toString().indexOf(this.usernameContains) !== -1
          }
        },
        {
          text: 'Role', value: 'role', filter: value => {
            if (!this.roleContains) return true
            return value && value.toString().indexOf(this.roleContains) !== -1
          }
        },
        {text: 'Created At', value: 'createdAt'},
        {text: 'Updated At', value: 'updatedAt'},
        {
          text: 'Actions', value: 'delete', sortable: false, filter: isDisabled => {
            return this.shouldDisplayDisabled || !isDisabled
          }
        },]
    },
  },
  data() {
    return {
      state: {},
      search: null,
      roleContains: "",
      users: [],
      usernameContains: "",
      shouldDisplayDisabled: true,
      activeItem: null,
    }
  },

}
</script>
<style scoped>
.color--red {
  color: red;
}
</style>