<template>
  <v-app>
    <nav-component :drawer="true" title="Admin / Manage Customers"/>
    <v-main class="pt-2 pt-md-0">

      <!-- Opened from disable button inside table-->
      <v-dialog v-model="disableDialog" max-width="600px">
        <v-card>
          <v-card-title class="text-h6"
                        v-text="activeItem.delete ? 'Are you sure you want to enable customer ' + activeItem.customerId + '?'
                        : 'Are you sure you want to disable customer ' + activeItem.customerId + '?'"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDisableDialog()">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="disableItemConfirm()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      `
      <!--Opened from "New customer" or "update" buttons-->
      <v-dialog v-model="dialog" width="70%">
        <v-card style="display: flex; flex-direction: column;">
          <v-card-title v-text="editedIndex === -1 ? 'New Customer' : 'Update Customer'"/>
          <v-card-text style="overflow-y: scroll;">
            <v-form>
              <v-text-field label="Customer ID"
                            v-model="activeItem.customerId"
                            :disabled="editedIndex !== -1"
                            :rules="[validationRules.httpCustomer]"/>
              <v-text-field label="Display Name" v-model="activeItem.displayName" :rules="[validationRules.notBlank]"/>
              <v-switch v-if="editedIndex !== -1"
                        label="change username / Reset password"
                        v-model="state.resetPassword"/>
              <v-alert v-if="state.resetPassword" type="warning" style="margin-top: 10px;">
                Changes in below fields will impact live customers
              </v-alert>
              <v-text-field v-if="editedIndex === -1 || state.resetPassword"
                            @focusin="allowedURLsPopup = true" @focusout="allowedURLsPopup = false"
                            label="Allowed callback domains (comma delimited list, no spaces)"
                            v-model="activeItem.allowedCallbackUrls"/>
              <pre v-if="allowedURLsPopup" class="popup">
                  {{ allowedURLsPreview }}
              </pre>
              <v-text-field v-if="editedIndex === -1 || state.resetPassword"
                            label="Username"
                            v-model="activeItem.username" :rules="[validationRules.notBlank]"/>
              <v-text-field v-if="editedIndex === -1 || state.resetPassword"
                            label="Password"
                            type="password"
                            v-model="activeItem.password"
                            :rules="[validationRules.password]"/>
              <v-text-field v-if="editedIndex === -1 || state.resetPassword"
                            label="Retype Password"
                            type="password"
                            v-model="activeItem.retypePassword"
                            :rules="[validationRules.passRetype]"/>
            </v-form>
          </v-card-text>
          <v-btn class="card"
                 color="secondary"
                 v-text="editedIndex === -1 ? 'ADD' : 'UPDATE'"
                 @click="submit()"
                 style="margin-left: 35% ; max-width: 30%;"/>
        </v-card>
      </v-dialog>

      <v-data-table hide-default-footer
                    :items-per-page="50"
                    :headers="headers()"
                    :items="customers">
        <template v-slot:top>
          <v-toolbar flat color=" blue-grey lighten-5">
            <v-toolbar-title>Customer list</v-toolbar-title>
            <v-switch label="Display disabled" v-model="shouldDisplayDisabled"/>
            <v-spacer/>
            <v-btn :disabled="!hasRole(['ROLE_ADMIN'])"
                   color="secondary" @click="createItem">
              <v-icon left>mdi-plus</v-icon>
              New Item
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:body.append>
          <tr>
            <td>
              <v-text-field v-model="customerIdContains" type="text" label="customer ID"/>
            </td>
            <td/>
            <td/>
            <td>

            </td>
          </tr>
        </template>
        <template v-slot:item.delete="{item}">
          <v-icon v-if="hasRole(['ROLE_ADMIN'])"
                  small class="mr-2" @click="editItem(item)">mdi-pencil
          </v-icon>
          <v-icon v-if="hasRole(['ROLE_ADMIN'])" @click="disableItem(item)" small class="mr-2"
                  :class="{'color--red': item.delete}">
            mdi-block-helper
          </v-icon>
          <span v-if="!hasRole(['ROLE_ADMIN']) && item.delete" type="text">Disabled</span>
        </template>
      </v-data-table>


    </v-main>
  </v-app>
</template>

<script>

import utils from '@/mixins/utils'
import NavComponent from '@/components/NavComponent.vue'

export default {
  async mounted() {
    this.customers = await this.getCustomers()
  },

  mixins: [utils],
  components: {NavComponent},

  data() {
    return {
      dialogDelete: false,
      customer: null,
      state: {},
      customers: [],
      customerIdContains: "",
      shouldDisplayDisabled: false,
      displayName: null,
      Username: null,
      originalUsername: "",
      originalDisplayName: "",
      resetPassword: false,
      editedIndex: -1,
      dateFormat: 'D/MMM/YY',
      dialog: false,
      disableDialog: false,
      allowedURLsPopup: false,
      activeItem: {
        customerId: "",
        displayName: "",
        validityDays: "",
        expirationDate: "1970-01-01",
        username: "",
        password: "",
        retypePassword: "",
        jwtSecret: "",
        apiSecret: "",
        delete: false,
        allowedCallbackUrls: "",
      },
      defaultItem: {
        customerId: "",
        displayName: "",
        validityDays: "",
        expirationDate: "1970-01-01",
        username: "",
        password: "",
        retypePassword: "",
        jwtSecret: "",
        apiSecret: "",
        delete: false,
        allowedCallbackUrls: "",
      },
      validationRules: {
        password: v => (!v || /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!()])(?=\S+$).{32,}$/.test(v)) || 'password must be at least 32 characters long. It must contain at least one digit, lowercase letter, uppercase letter and special character @#$%^&*!()',
        passRetype: value => (value && value === this.activeItem.password) || 'Passwords are not the same',
        httpCustomer: value => (/^[a-zA-Z0-9\-_.]+$/.test(value)) || 'Invalid customer ID',
        notBlank: value => (/\S+/.test(value)) || 'Field cannot be empty',
      },
    }
  },
  computed: {
    allowedURLsPreview() {
      if (!this.activeItem.allowedCallbackUrls) return `default value:\nhttps://*${this.activeItem.customerId}*`
      return this.activeItem.allowedCallbackUrls
          .split(',')
          .map(domain => `https://*${domain.trim()}*`)
          .join('\n')
    }
  },
  methods: {
    async disableItemConfirm() {
      this.activeItem.delete = !this.activeItem.delete
      await this.httpRequest('POST', 'api/customer/disable', null, this.activeItem)
      this.closeDisableDialog(true)
    },
    async closeDisableDialog(shouldRefresh) {
      this.disableDialog = false
      this.activeItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      if (shouldRefresh)
        this.customers = await this.getCustomers()
    },
    async submit() {
      this.activeItem.resetPassword = this.resetPassword
      if ((this.editedIndex === -1 || this.activeItem.resetPassword) &&
          (this.validationRules.password(this.activeItem.password) !== true || this.validationRules.passRetype(this.activeItem.retypePassword) !== true)) {
        return;
      }
      if (this.validationRules.notBlank(this.activeItem.displayName) !== true ||
          this.validationRules.notBlank(this.activeItem.username) !== true) {
        return;
      }

      if (this.editedIndex === -1) {
        if (this.validationRules.httpCustomer(this.activeItem.customerId) !== true) {
          return;
        }
        this.activeItem.validityDays = 3650
        await this.httpRequest('POST', 'api/customer/add', null, this.activeItem)
      } else {
        await this.httpRequest('POST', 'api/customer/update', null, this.activeItem)
      }
      await this.close(true)
    },

    createItem() {
      this.editedIndex = -1
      this.activeItem = Object.assign({}, this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.editedIndex = this.customers.indexOf(item)
      this.activeItem = Object.assign({}, item)
      this.originalUsername = item.username
      this.originalDisplayName = item.displayName
      this.resetPassword = false
      this.dialog = true
    },
    disableItem(item) {
      this.editedIndex = this.customers.indexOf(item)
      this.activeItem = Object.assign({}, item)
      this.disableDialog = true
    },

    async close(shouldRefresh) {
      this.dialog = false
      this.editedIndex = -1
      if (shouldRefresh)
        this.customers = await this.getCustomers()
    },

    headers() {
      return [
        {
          text: 'Customer ID', value: 'customerId', filter: value => {
            if (!this.customerIdContains) return true
            return value && value.toString().indexOf(this.customerIdContains) !== -1
          }
        },
        {text: 'Display name', value: 'displayName'},
        {text: 'Username', value: 'username'},
        {
          text: 'Actions', value: 'delete', sortable: false, filter: isDisabled => {
            return this.shouldDisplayDisabled || !isDisabled
          }
        },
      ];
    },
  }
}
</script>

<style scoped>
.color--red {
  color: red;
}

.popup {
  white-space: discard;
  position: absolute;
  text-align: left;
  background-color: lightgray;
  padding: 5px;
  margin: 10px;
  border-radius: 5px;
  z-index: 1000;
}
</style>
