var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('nav-component',{attrs:{"drawer":true,"title":"Customer Reports"}},[(_vm.hasRole(['ROLE_ADMIN','ROLE_CUSTOMER_SUCCESS','ROLE_READ_ONLY_ADMIN']))?_c('div',{staticClass:"flexRow",staticStyle:{"margin-top":"20px"}},[_c('v-select',{attrs:{"items":_vm.customerIds,"label":"Mno ID"},model:{value:(_vm.customerId),callback:function ($$v) {_vm.customerId=$$v},expression:"customerId"}})],1):_vm._e()]),_c('v-main',{staticClass:"pt-2 pt-md-0"},[_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.durationRangePopup),callback:function ($$v) {_vm.durationRangePopup=$$v},expression:"durationRangePopup"}},[_c('v-date-picker',{attrs:{"range":"","color":"secondary","max":_vm.$date(new Date(), 'yyyy-MM-dd')},on:{"change":function($event){return _vm.onDurationChanged()}},model:{value:(_vm.durationRange),callback:function ($$v) {_vm.durationRange=$$v},expression:"durationRange"}})],1),_c('v-container',{attrs:{"fluid":""}},[_c('Totals',{ref:"totals",attrs:{"customerId":_vm.customerId}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"flat":"","color":"blue-grey lighten-5"}},[_c('v-toolbar-title',[_vm._v("Reports")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"5","sm":"4"}},[_c('v-select',{attrs:{"items":[
                            {text: 'Monthly', value: 'MONTHLY'},
                            {text: 'Weekly', value: 'WEEKLY'},
                            {text: 'Daily', value: null}]},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1),_c('v-col',{attrs:{"cols":"5","sm":"4"}},[_c('v-select',{attrs:{"placeholder":"Duration","items":[
                            {text: 'Last month', value: null},
                            {text: '30 days', value: 'DAYS30'},
                            {text: '7 days', value: 'DAYS7'},
                            {text: 'Today', value: 'TODAY'},
                            {text: 'Yesterday', value: 'YESTERDAY'},
                            {text: 'Range', value: 'RANGE'}]},on:{"input":function($event){return _vm.onDurationSelected()},"change":function($event){return _vm.onDurationSelected()}},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"block":"","color":"secondary"},domProps:{"textContent":_vm._s('GO')},on:{"click":_vm.go}})],1)],1)],1)],1)],1),_c('v-tabs',{attrs:{"vertical":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticStyle:{"justify-content":"left","text-transform":"none"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v(" Users ")],1),_c('v-tab',{staticStyle:{"justify-content":"left","text-transform":"none"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cellphone-cog")]),_vm._v(" Transactions ")],1),_c('v-tab',{staticStyle:{"justify-content":"left","text-transform":"none"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-home-analytics")]),_vm._v(" SDK Installations ")],1),_c('v-tab',{staticStyle:{"justify-content":"left","text-transform":"none"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-numeric")]),_vm._v(" MSISDN ")],1),_c('v-tab',{staticStyle:{"justify-content":"left","text-transform":"none"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-numeric")]),_vm._v(" Request ID ")],1),_c('v-tab-item',[_c('UsersReport',{ref:"usersReport"})],1),_c('v-tab-item',[_c('TransactionsReport',{ref:"transactionsReport"})],1),_c('v-tab-item',[_c('SDKReport',{ref:"sdkReport"})],1),_c('v-tab-item',[_c('MSISDNReport',{ref:"msisdnReport"})],1),_c('v-tab-item',[_c('RequestIDReport',{ref:"requestIdReport"})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }