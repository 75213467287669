import axios from '../plugins/axios';
import authService from '@/authService';
import {saveAs} from 'file-saver'

export default {
  methods: {
    async routeToLogin(e) {
      if (this.$route.path !== '/login')
         await this.$router.replace({name: 'login'});
      window.history.replaceState(null, null, '/');
      if (e)
        throw e;
    },
    async logout() {
      if (authService.isLoggedIn()) {
        await this.httpRequest('POST', 'api/auth/logout');
        authService.logout();
      }
      await this.routeToLogin();
    },
    async getCustomers() {
      const customers = await this.httpRequest('GET', 'api/customer/getall');
      return customers.data;
    },
    async httpRequest(method, url, headers, data) {
      if (authService.isTokenSet()) {
        headers = {...headers, Authorization: `Bearer ${authService.getToken()}`, username: authService.getUsername()};
      }
      return axios({
        method: method,
        url: url,
        data: data,
        headers: headers
      }).then(response => response, error => this.handleResponseError(error))
    },
    async handleResponseError(error) {
      if (error.response.status === 403 && !error.response.headers['x-error-message']) {
        //user is attempting to use outdated JWT, routing to re-login
        authService.logout();
        await this.routeToLogin(error);
      } else { //pass rejection back to caller
        return Promise.reject(error);
      }
    },
    hasRole(roles) {
      return authService.hasRole(roles);
    },
    csvGeneric(fileNamePrefix, blobHeaders, processItem) {
      let blobData = blobHeaders;
      this.results.forEach(item => {
        blobData += processItem(item);
      })
      const currentDate = this.$date(new Date(), 'yyyy-MM-dd_HH:mm:ss')
      saveAs(new Blob([blobData], {type: "text/csv;charset=utf-8"}), `${fileNamePrefix}${currentDate}.csv`)
    }
  },
  data() {
    return {
      roles: {
        'ROLE_ADMIN': 'Admin',
        'ROLE_CUSTOMER_USER': 'Customer User',
        'ROLE_MNO_USER': 'MNO User',
        'ROLE_READ_ONLY_ADMIN': 'Read only admin',
        'ROLE_CUSTOMER_SUCCESS': 'Customer Success',
      },
      currentUser: null
    }
  },
  head() {
    return {
      title: 'Unibeam'
    }
  }
}
