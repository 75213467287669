import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueTimeago from 'vue-timeago'
import VueDateFns from "vue-date-fns";
import 'vuetify/dist/vuetify.min.css'
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import {routes} from "./routes";
import VueRouter from "vue-router";
import authService from '@/authService';

authService.init();

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !authService.isLoggedIn()) {
    next('/login');
    window.history.replaceState(null, null, '/');
  } else if (to.matched.some(record => record.meta.mfa) && !authService.isMFA()) {
    next('/login');
    window.history.replaceState(null, null, '/');
  } else {
    next();
  }
});

Vue.use(VueRouter)
Vue.config.productionTip = false

axios.create({
  baseURL: process.env.baseUrl
})

Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en-US', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    ja: require('date-fns/locale/en-US')
  }
})

Vue.use(VueDateFns);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
