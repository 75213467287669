
export default {
  init() {
    //authState.user was added recently, so need 2nd part check for "upgrade" purposes
    if (!localStorage.authState || !JSON.parse(localStorage.authState).user) {
      this.authState = {loggedIn: false, mfa: false, user: {username: null, roles: [], token: null}};
      this.save();
    } else {
      this.authState = JSON.parse(localStorage.authState);
    }
  },
  isLoggedIn() {
    return this.authState.loggedIn;
  },
  isMFA() {
    return this.authState.mfa;
  },
  login() {
    this.authState.loggedIn = true;
    this.save();
  },
  logout() {
    localStorage.clear();
    this.authState = {loggedIn: false, mfa: false, user: {username: null, roles: [], token: null}};
    this.save();
  },
  setMFA() {
    this.authState.mfa = true;
    this.save();
  },
  setToken(token) {
    this.authState.user.token = token;
    this.save();
  },
  getToken() {
    return this.authState.user.token;
  },
  setUsername(username) {
    this.authState.user.username = username;
    this.save();
  },
  getUsername() {
    return this.authState.user.username;
  },
  isTokenSet() {
    return this.authState.user.token !== null && this.authState.user.token !== undefined;
  },
  setSignedInUser(data) {
    this.authState.user.roles = data.authorities;
    this.authState.user.token = data.token;
    this.authState.loggedIn = true;
    this.save();
  },
  hasRole(roles) {
    return this.authState.user.roles !== null && this.authState.user.roles.some(authority => roles.includes(authority));
  },
  save() {
    localStorage.authState = JSON.stringify(this.authState);
  }
};
