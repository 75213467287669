<template>
  <v-app>
    <nav-component :drawer="false" title="Login"/>
    <v-main>
      <div style="alignment: center;">
        <v-card style="max-width: 600px; margin: 150px auto auto auto; padding: 50px;">
          <h1>Login</h1>
          <div :class="{'shake': shakeErrorMessage}"
               style="display: flex; flex-direction: row; justify-content: left; color: red;" v-if="errorMessage">
            <span v-text="errorMessage"/>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: center;">
            <span style="width: 80px; margin-top: 21px; margin-right: 10px;">Email:</span>
            <v-text-field type="email" v-model="user" @input="() => {user = user.toLowerCase()}"
                          :rules="[validationRules.email]"/>
          </div>
          <div>
            <loading-overlay :show="loading"></loading-overlay>
            <p v-if="loading"></p>
            <div class="framed-text" v-if="loading">Please Approve the login request on your phone</div>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: center;" v-if="usePassword">
            <span style="width: 80px; margin-top: 21px; margin-right: 10px;">Password:</span>
            <v-text-field type="password" v-model="password" :rules="[validationRules.password]"/>
            <v-btn color="gey" @click="changeUsePassword">Use MSISDN</v-btn>
          </div>
          <div style="display: flex; flex-direction: row; justify-content: center;" v-if="!usePassword">
            <span style="width: 80px; margin-top: 21px; margin-right: 10px;">Msisdn:</span>
            <v-text-field v-model="msisdn" autocomplete="off" :rules="[validationRules.msisdn]"/>
            <v-btn color="gey" @click="changeUsePassword">Use Password</v-btn>
          </div>
          <v-btn color="secondary" @click="signIn('signIn', true)">Sign In</v-btn>
          <v-btn class="float-right" color="orange" @click="signIn('api/auth/unibeamSignIn', false)">SIA</v-btn>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>
<style scoped>
.framed-text {
  border: 2px solid black;
  font-weight: bold;
  padding: 20px;
  font-size: 22px;
  text-align: center;
  color: red;
  width: 80%;
  margin: 0 auto;
}
</style>
<script>

import jsSHA from 'jssha'
import utils from "@/mixins/utils";
import NavComponent from "@/components/NavComponent";
import authService from '@/authService';
import LoadingOverlay from '@/components/LoadingOverlay.vue'; // Import your LoadingOverlay component

export default {
  mixins: [utils],
  data() {
    return {
      user: null,
      password: null,
      autofilled: false,
      loading: false,
      usePassword: true,
      msisdn: null,
      validationRules: {
        // eslint-disable-next-line no-useless-escape
        email: v => (v && /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(v)) || 'Please enter a valid email',
        password: v => (v && /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!()])(?=\S+$).{8,}$/.test(v)) || 'password must be minimum 8 characters, must contain at least one number, at least one capital letter, at least one small letter and at least one special character',
        msisdn: v => (!v || /^[0-9]{10,13}$/.test(v)) || 'MSISDN does not meet the required criteria. ' + ' sign not allowed',
      },
      errorMessage: null,
      shakeErrorMessage: false,
    }
  },
  watch: {
    'user'() {
      this.autofilled = true;
    },
    'password'() {
      this.autofilled = true;
    },
    'msisdn'() {
      this.autofilled = false;
    }
  },
  components: {
    NavComponent,
    LoadingOverlay
  },
  methods: {
    async changeUsePassword() {
      this.usePassword = !this.usePassword;
      // Reset msisdn field when switching from password to msisdn
      if (this.usePassword) {
        this.msisdn = ''; // Reset msisdn to empty string
      } else {
        this.password = ''; // Reset password to empty string
      }
    },
    async signIn(authorizationRoute, hasAuthorizationPage) {
      authService.setToken(null);
      authService.setUsername(null);
      let hashedPassword = null;
      if (this.validationRules.email(this.user) !== true) {
        return;
      }
      if (this.usePassword) {
        if (!this.password || this.password.length === 0) {
          return;
        }
        const shaObj = new jsSHA("SHA-512", "TEXT", {encoding: "UTF8"});
        shaObj.update(this.password);
        hashedPassword = shaObj.getHash('HEX');
      } else {
        if (this.validationRules.msisdn(this.msisdn) !== true) {
          return
        }
        await this.httpRequest('GET', 'api/auth/getPasswordByMsisdn', {
          "username": this.user,
          "msisdn": this.msisdn
        }).then(result => hashedPassword = result.data, error => this.handleError(error));
      }

      await this.httpRequest('GET', 'api/auth/authenticate', {'Authorization': 'Basic ' + btoa(this.user + ':' + hashedPassword)})
          .then(authenticationResult => this.attemptAuthorize(authenticationResult, authorizationRoute, hasAuthorizationPage),
              onRejected => this.handleError(onRejected));
    },
    handleError(error) {
      const prevErrorMessage = this.errorMessage;
      if (error.response && error.response.headers['x-error-message']) {
        this.errorMessage = error.response.headers['x-error-message'];
      } else {
        this.errorMessage = 'Unknown error occurred';
      }
      if (prevErrorMessage === this.errorMessage)
        this.triggerShake();
    },
    triggerShake() {
      this.$nextTick(() => {
        this.shakeErrorMessage = true;
        setTimeout(() => {
          this.shakeErrorMessage = false;
        }, 1000); // Duration of the shake animation
      });
    },
    async attemptAuthorize(authenticationResult, authorizationRoute, hasAuthorizationPage) {
      authService.setToken(authenticationResult.data.token);
      authService.setUsername(this.user);
      if (hasAuthorizationPage === true) { //'Next' button clicked, has OTP page
        console.log('authenticationResult.data.demoUser: ' + authenticationResult.data.demoUser);
        if (authenticationResult.data.demoUser === true) {
          await this.httpRequest('POST', 'api/auth/demoSignIn')
              .then(signInResult => {
                    authService.setSignedInUser(signInResult.data);
                    this.$router.replace({path: '/'});
                  },
                  error => this.handleError(error));
        } else {
          authService.setMFA();
          await this.$router.replace({path: authorizationRoute});
        }
      } else {  // "SIA" button clicked
        this.loading = true;
        await this.httpRequest('POST', authorizationRoute)
            .then(signInResult => {
                  authService.setSignedInUser(signInResult.data);
                  this.$router.replace({path: '/'});
                },
                error => this.handleError(error));
        this.loading = false;
      }
    },
  }
}
</script>
